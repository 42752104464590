import { GraphStat } from "@components/admin/stats/StatsHelper";
import { Box, Typography } from "@material-ui/core";
import { formatLargeNumber } from "@utils/formatting";

export default function StatCountComponent(props: {stats: GraphStat[], title: string, width: string}) {
    const allStat = props.stats.find(x => x.title === "All");
    return <Box width={props.width}>
      <Box display="flex" justifyContent="center">
        <Typography style={numberStyle}>
          {allStat?.total === undefined ? 0 : formatLargeNumber(allStat.total)}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography style={textStyle}>
          {props.title}
        </Typography>
      </Box>
    </Box>;
}

const numberStyle = {fontSize: "25pt"}
const textStyle = {fontSize: "12pt"}
