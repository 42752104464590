import LineChartSectionComponent from "@components/admin/stats/LineChartSectionComponent";
import StatCountComponent from "@components/admin/stats/StatCountComponent";
import { getRelevantChartData, GraphStat } from "@components/admin/stats/StatsHelper";
import StatsSkeleton from "@components/admin/stats/StatsSkeleton";
import { Box, Card, CardContent } from "@material-ui/core";
import { StatsComponentProps } from "src/pages/admin/stats";

export default function StatsComponent(props: StatsComponentProps): JSX.Element {

	const numberOfBoxes = props.keysForGraphs.filter(x => x.hasTotal).length;
	const numberOfCharts = props.keysForGraphs.filter(x => x.hasGraph).length;

	if (props.stats === undefined) {
		return <StatsSkeleton noOfBoxes={numberOfBoxes} noOfCharts={numberOfCharts} />
	}

	const listOfStatsFromKeys: GraphStat[][] = [];

	props.keysForGraphs.forEach(element => {
		if (!element.hasGraph && !element.hasTotal) {
			return;
		}
		listOfStatsFromKeys.push(getRelevantChartData(
			{
				country: element.unknownCountry ? "unknown" : props.country,
				key: element.key,
				stats: props.stats
			},
			props.fromDate,
			props.toDate))
	});

	return <Card>
		<CardContent style={{
			padding: 48
		}}>
			<Box display="flex" flexDirection="row" style={{
				marginBottom: 48
			}}>
				{props.keysForGraphs.map((e, i) => {
					if (!e.hasTotal) {
						return <div key={i}></div>
					}
					return <StatCountComponent
						stats={listOfStatsFromKeys[i]}
						key={i}
						title={e.title}
						width={100 / numberOfBoxes + "%"} />
				})}
			</Box>
			{props.keysForGraphs.map((e, i) => {
				if (!props.fromDate || !props.toDate) {
					return;
				}
				if (!e.hasGraph) {
					return <div key={i}></div>
				}
				return <LineChartSectionComponent
					stats={listOfStatsFromKeys[i]}
					key={i}
					title={e.title}
					from={props.fromDate}
					to={props.toDate}/>
			})}
		</CardContent>
	</Card>

}

