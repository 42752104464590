import { Box, Container } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";


export default function StatsSkeleton(props: {noOfBoxes: number, noOfCharts: number}): JSX.Element {
  const widthOfBoxes = props.noOfBoxes / 100

  return <Box>
    <Box display="flex" flexDirection="row">
      {renderBoxes(widthOfBoxes, props.noOfBoxes)}
    </Box>
    {renderGraphs(props.noOfCharts)}
  </Box>
}

function renderGraphs(noOfCharts: number) {
  let elements = []
  for (let i = 0; i < noOfCharts; i++) {
    elements.push(GraphSkeleton(i))
  }
  return elements;
}

function GraphSkeleton(index: number): JSX.Element {
  return <Box m={5} key={index}>
    <Box m={2}> 
      <Skeleton variant="rect" height={35} />
    </Box>
    
    <Container maxWidth={false} style={{ height: "500px", width: "100%" }}>
      <Skeleton variant="rect" height={500}/>
    </Container>
  </Box>
}

function BoxSkeleton(props: {index: number, width: number}): JSX.Element {
  return <Box key={props.index}>
    <Box display="flex" justifyContent="center" m={2}>
      <Skeleton variant="rect" height={50} width={props.width}/>
    </Box>
    <Box display="flex" justifyContent="center"  m={2}>
      <Skeleton variant="rect" height={25} width={props.width}/>
    </Box>
  </Box>;
}

function renderBoxes(width: number, noOfBoxes: number) {
  if(typeof window === "undefined")
    return [];

  let elements = []
  for (let i = 0; i < noOfBoxes; i++) {
    elements.push(BoxSkeleton({
      index: i, 
      width: window.screen.height/noOfBoxes}))
  }
  return elements;
}